import React, { Fragment } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { POST_PREVIEW_TEXT_LENGTH } from "../utils/constants"
import { getTextFromSlices } from "../utils/helpers"
import { getPath } from "../utils/gatsby-node-helpers"

const BlogFeed = ({ posts, categories }) => {
  return (
    <Fragment>
      <div className="row">
        {posts &&
          posts.map((post, i) => {
            return (
              <div
                key={i}
                className="col-md-6 col-lg-4 mb-4 d-flex flex-column"
              >
                <Link
                  to={getPath({
                    docType: "blog_post",
                    lang: post.lang,
                    uid: post.uid,
                  })}
                >
                  <img src={post.data.cover.url} className="img-fluid" />
                </Link>
                <div className="py-3">
                  <div className="mb-2">
                    <Link
                      to={getPath({
                        docType: "category",
                        lang: post.lang,
                        uid: post.data.category.uid,
                      })}
                      className="text-muted text-decoration-none"
                    >
                      <small className="border-bottom border-success">
                        {
                          categories.find(
                            c =>
                              c.uid === post.data.category.uid &&
                              c.lang === post.lang,
                          )?.data.category
                        }
                      </small>
                    </Link>
                  </div>
                  <h4 className="blog-teaser-title">
                    <Link
                      to={getPath({
                        docType: "blog_post",
                        lang: post.lang,
                        uid: post.uid,
                      })}
                      className="text-body"
                    >
                      {post.data.title.text}
                    </Link>
                  </h4>
                  <p className="m-0">
                    {post.data.body &&
                      getTextFromSlices(post.data.body).substring(
                        0,
                        POST_PREVIEW_TEXT_LENGTH,
                      )}
                    &nbsp;...
                  </p>
                </div>
                <div className="border-top mt-auto" />
              </div>
            )
          })}
      </div>
    </Fragment>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query AllCategories {
        allPrismicCategory {
          nodes {
            data {
              category
            }
            uid
            lang
          }
        }
      }
    `}
    render={data => (
      <BlogFeed categories={data.allPrismicCategory.nodes} {...props} />
    )}
  />
)
