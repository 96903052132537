export const POST_PREVIEW_TEXT_LENGTH = 158

export const FULL_PREVIEW_POSTS_COUNT = 5

export const BLOG_ROLL_INDEX_LAST_SHORT_PREVIEW_POST = 11

export const ITEMS_PER_PAGE_FEED = 6

export const APIErrorMessageRecruiterSignup = {
  "en-us": {
    email: "Already registered user",
    companyName: "Already registered entity",
    default: "Please check your input.",
  },
  "de-de": {
    email: "Bereits registrierter Benutzer",
    companyName: "Bereits registriertes Unternehmen",
    default: "Bitte Eingabe überprüfen.",
  },
}
