import React from "react"
import { Link } from "gatsby"
import cn from "classnames"

const PAGES_TO_SHOW = 3

const BlogPagination = ({ currentPage, maxPage, pathname }) => {
  const allPages = Array.from(Array(maxPage), (_, index) => index + 1)
  const pages =
    currentPage <= maxPage / 2
      ? allPages.slice(0, PAGES_TO_SHOW)
      : allPages.slice(-PAGES_TO_SHOW)
  const isStart = currentPage <= 1
  const isEnd = currentPage >= maxPage

  return (
    <nav aria-label="navigation" className="pb-3">
      <ul className="pagination">
        <li
          className={cn("page-item", {
            disabled: isStart,
          })}
        >
          <Link
            className="page-link"
            aria-label="First"
            to={pathname + "1"}
            aria-disabled={isStart}
            tab-index={isStart ? "-1" : ""}
          >
            <span aria-hidden="true">&laquo;</span>
          </Link>
        </li>
        <li
          className={cn("page-item", {
            disabled: isStart,
          })}
        >
          <Link
            className="page-link"
            aria-label="Previous"
            to={pathname + (currentPage - 1)}
            aria-disabled={isStart}
            tab-index={isStart ? "-1" : ""}
          >
            <span aria-hidden="true">&lsaquo;</span>
          </Link>
        </li>
        {currentPage > maxPage / 2 && (
          <li className="page-item disabled">
            <button className="page-link">...</button>
          </li>
        )}
        {pages.map((page, i) => (
          <li
            key={i}
            className={cn("page-item", {
              active: page === currentPage,
            })}
          >
            <Link
              className="page-link"
              to={pathname + page}
              aria-disabled={page === currentPage}
              tab-index={page === currentPage ? "-1" : ""}
            >
              {page}
            </Link>
          </li>
        ))}
        {currentPage <= maxPage / 2 && (
          <li className="page-item disabled">
            <button className="page-link">...</button>
          </li>
        )}
        <li
          className={cn("page-item", {
            disabled: isEnd,
          })}
        >
          <Link
            className="page-link"
            aria-label="Next"
            to={pathname + (currentPage + 1)}
            aria-disabled={isEnd}
            tab-index={isEnd ? "-1" : ""}
          >
            <span aria-hidden="true">&rsaquo;</span>
          </Link>
        </li>
        <li
          className={cn("page-item", {
            disabled: isEnd,
          })}
        >
          <Link
            className="page-link"
            aria-label="Last"
            to={pathname + maxPage}
            aria-disabled={isEnd}
            tab-index={isEnd ? "-1" : ""}
          >
            <span aria-hidden="true">&raquo;</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
export default BlogPagination
